<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="Format"
        readonly
        v-bind="attrs"
        v-on="on"
        :label="label"
        :outlined="outlined"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :append-icon="appendIcon"
        :append-outer-icon="appendOuterIcon"
        :rules="rules"
        :disabled="disabled"
        @click:prepend="$emit('click:prepend')"
        @click:append-outer="$emit('click:append-outer')"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="visible = false"
      locale="ja-jp"
      type="month"
      no-title
      scrollable
      @change="change"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: String,
    format: String,
    label: String,
    prependIcon: String,
    prependInnerIcon: String,
    prependIconNone: Boolean,
    appendIcon: String,
    appendOuterIcon: String,
    outlined: Boolean,
    width: { type: [Number, String] },
    maxWidth: { type: String },
    rules: Array,
    disabled: Boolean,
    addDayToValue: Boolean
  },
  data() {
    return {
      visible: false,
      date: ""
    };
  },
  computed: {
    Date: {
      get() {
        return !this.value ? this.date : this.value;
      },
      set(v) {
        this.date = v;
      }
    },
    Format() {
      if (!this.Date) return this.Date;

      const date = new Date(this.Date);

      return `${date.getFullYear()} 年 ${date.getMonth() + 1} 月`;
    }
  },
  watch: {
    date: function(v) {
      this.$emit("input", v);
    }
  },
  methods: {
    change(e) {
      if (this.addDayToValue) {
        e = e += "-01";
        this.date = e;
      }
      this.$emit("change", e);
    }
  },
  mounted() {
    if (this.value) {
      this.date = this.value;
    }
  }
};
</script>
